import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { emailFormatValidator, noSpecialCharsEmailValidator, containsSpecialChars, invalidFormat} from 'src/app/shared/utils/util';
import { LaunchDarklyService } from 'remarketing-angular-library';
import { TranslocoService } from '@ngneat/transloco';
import { WizardComponent } from 'angular-archwizard';
import { assign, chain, filter, get, isEmpty, template } from 'lodash';

import { AnalyticsService } from 'smartauction-frontend-micro-library';
import { RegistrationWizardStep } from '../../interfaces/regisration-wizard-step.interface';
import { RegistrationStateService } from '../../services/registration-state/registration-state.service';

@Component({
  selector: 'app-registration-step2',
  templateUrl: './registration-step2.component.html',
  styleUrls: ['./registration-step2.component.scss']
})
export class RegistrationStep2Component implements OnInit, OnDestroy, RegistrationWizardStep {
  private readonly destroyed$ = new Subject();
  readonly CONSTANTS = {
    stepTitle: '',
    title: '',
    firstName: '',
    lastName: '',
    mobilePhone: '',
    optional: '',
    email: '',
    auctionAccess: '',
    selectOne: '',
    auctionAccessIdPlaceHolder: '',
    formInvalidSingular: '',
    formInvalidPlural: '',
    reviewErrors: '',
    errorMessages: {
      firstName: '',
      lastName: '',
      mobilePhone: '',
      invalidFormatEmail: '',
      emailContainsSpecialChars: '',
      auctionAccess: '',
    },
    button: {
      add: '',
      cancel: '',
      continue: ''
    },
    mobilePhoneClause: ''
  };

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  userForm: UntypedFormGroup;

  firstName: UntypedFormControl;
  lastName: UntypedFormControl;
  mobilePhone: UntypedFormControl;
  email: UntypedFormControl;
  auctionAccess: UntypedFormControl;
  featureMobileNotif: boolean;
  tenantName: string;
  showAuctionAccessId = true;
  isAuctionAccessIdOptional = true;
  auctionAccessIndividualIdMinValue = 100000000;
  auctionAccessIndividualIdMaxValue = 199999999;

  @Input() current: boolean;

  private readonly phonePattern = '^([0-9]){10}$';
  private readonly numberPattern = '^([0-9]){9}$';

  constructor(
    private wizard: WizardComponent,
    private registrationState: RegistrationStateService,
    private analyticsService: AnalyticsService,
    private readonly _launchDarklyService: LaunchDarklyService,
    private readonly translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.getTranslations();
    this.buildForm();
    this.userForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(values => assign(this.registrationState, values));

    this.tenantName = this.registrationState.portalIdentifier;
    this.featureMobileNotif = this._launchDarklyService.getFeatureFlag('login-mobileNotificationTextEnabled', false);
    this.showAuctionAccessId = !this._launchDarklyService.getFeatureFlag('userRegistration-auctionAccessId-100MEnforceEnabled', false);
    this.recreateAuctionAccessId();
  }

  get totalNumberErrors(): number {
    const controls = this.userForm.controls;
    return filter(controls, control => control.invalid).length;
  }

  get displayFormErrorsMessage(): string {
    const isPlural = this.totalNumberErrors > 1,
      tmplName = isPlural ? 'formInvalidPlural' : 'formInvalidSingular';
    return template(this.CONSTANTS[tmplName])({ n: this.totalNumberErrors });
  }

  // @TODO: Watch for any exceptions similar to this
  // Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
  // Previous value: 'undefined'. Current value: 'false'.
  // to fix these error added default value as false
  get isFormInvalid(): boolean {
    return (this.ngForm?.submitted && this.totalNumberErrors > 0) || false;
  }
  get isFirstNameInvalid(): boolean {
    return this.ngForm.submitted && this.firstName.invalid;
  }
  get isLastNameInvalid(): boolean {
    return this.ngForm.submitted && this.lastName.invalid;
  }
  get isMobilePhoneInvalid(): boolean {
    return this.ngForm.submitted && this.mobilePhone.invalid;
  }
  get isEmailInvalid(): boolean {
    return this.invalidFormatEmail || this.emailContainsSpecialChars;
  }

  get invalidFormatEmail(): boolean {
    return this.ngForm.submitted && (this.email.value === '' || this.email.hasError('invalidFormatEmail'));
  }

  get emailContainsSpecialChars(): boolean {
    return this.ngForm.submitted && !this.email.hasError('invalidFormatEmail') && this.email.hasError('emailContainsSpecialChars');
  }

  get isAuctionAccessInvalid(): boolean {
    return this.ngForm.submitted && this.auctionAccess.invalid;
  }

  get isPristine(): boolean {
    return chain(this)
      .pick('firstName', 'lastName', 'mobilePhone', 'email', 'auctionAccess')
      .map(control => isEmpty(control.value))
      .reduce((isPristine, state) => isPristine && state)
      .value();
  }

  handleContinue(): void {
    if (!this.userForm.valid) {
      this.focusElement('reviewErrors_step2');
    } else {
      this.wizard.goToNextStep();
    }

    const errors = [];
    if (this.isFirstNameInvalid) {
      errors.push(this.CONSTANTS.errorMessages.firstName);
    }
    if (this.isLastNameInvalid) {
      errors.push(this.CONSTANTS.errorMessages.lastName);
    }
    if (this.invalidFormatEmail) {
      errors.push(this.CONSTANTS.errorMessages.invalidFormatEmail);
    }
    if (this.emailContainsSpecialChars) {
      errors.push(this.CONSTANTS.errorMessages.emailContainsSpecialChars);
    }

    if (this.isAuctionAccessInvalid) {
      errors.push(this.CONSTANTS.errorMessages.auctionAccess);
    }

    this.analyticsService.trackEndpoint({
      formValues: {
        name: 'Registration Whitelabel User Info',
        status: this.userForm.invalid ? 'error' : 'success',
        errors: errors,
        type: '',
        step: '2',
        stepname: '',
        items: [
          {
            allow: 'no',
            error: this.isFirstNameInvalid ? this.CONSTANTS.errorMessages.firstName : '',
            name: 'First Name',
            track: 'yes',
            value: ''
          },
          {
            allow: 'no',
            error: this.isLastNameInvalid ? this.CONSTANTS.errorMessages.lastName : '',
            name: 'Last Name',
            track: 'yes',
            value: ''
          },
          {
            allow: 'no',
            error: this.invalidFormatEmail ? this.CONSTANTS.errorMessages.invalidFormatEmail : '',
            name: 'Email',
            track: 'yes',
            value: ''
          },
          {
            allow: 'no',
            error: this.emailContainsSpecialChars ? this.CONSTANTS.errorMessages.emailContainsSpecialChars : '',
            name: 'Email',
            track: 'yes',
            value: ''
          }
        ]
      }
    });
  }

  canCompleteStep(): boolean {
    return this.userForm.valid;
  }

  private buildForm() {
    this.firstName = new UntypedFormControl('', Validators.compose([Validators.required]));

    this.lastName = new UntypedFormControl('', Validators.compose([Validators.required]));

    this.mobilePhone = new UntypedFormControl('', {
      validators: Validators.pattern(this.phonePattern),
      updateOn: 'blur'
    });

    this.email = new UntypedFormControl('', [Validators.required, emailFormatValidator(), noSpecialCharsEmailValidator()]);

    this.auctionAccess = new UntypedFormControl('', Validators.compose([Validators.max(this.auctionAccessIndividualIdMaxValue), Validators.min(this.auctionAccessIndividualIdMinValue), Validators.pattern(this.numberPattern)]));

    this.userForm = new UntypedFormGroup({
      firstName: this.firstName,
      lastName: this.lastName,
      mobilePhone: this.mobilePhone,
      userEmail: this.email,
      auctionAccess: this.auctionAccess,
    });
  }

  focusElement(id) {
    setTimeout(() => {
      const message = document.getElementById(id) as HTMLElement;
      if (message) {
        message.focus();
      }
    }, 100);
  }

  getTranslations(){
    this.translocoService.selectTranslateObject('registration.registrationStep2').pipe(takeUntil(this.destroyed$)).subscribe(translate => {
      this.CONSTANTS.stepTitle = translate.stepTitle;
      this.CONSTANTS.title = translate.title;
      this.CONSTANTS.firstName = translate.firstName;
      this.CONSTANTS.lastName = translate.lastName;
      this.CONSTANTS.mobilePhone = translate.mobilePhone;
      this.CONSTANTS.optional = translate.optional;
      this.CONSTANTS.email = translate.email;
      this.CONSTANTS.auctionAccess = translate.auctionAccess;
      this.CONSTANTS.selectOne = translate.selectOne;
      this.CONSTANTS.auctionAccessIdPlaceHolder = translate.auctionAccessIdPlaceHolder;
      this.CONSTANTS.formInvalidSingular = translate.formInvalidSingular;
      this.CONSTANTS.formInvalidPlural = translate.formInvalidPlural;
      this.CONSTANTS.reviewErrors = translate.reviewErrors;
      this.CONSTANTS.errorMessages.firstName = translate.errorMessages.firstName;
      this.CONSTANTS.errorMessages.lastName = translate.errorMessages.lastName;
      this.CONSTANTS.errorMessages.mobilePhone = translate.errorMessages.mobilePhone;
      this.CONSTANTS.errorMessages.invalidFormatEmail = translate.errorMessages.invalidFormatEmail;
      this.CONSTANTS.errorMessages.emailContainsSpecialChars = translate.errorMessages.emailContainsSpecialChars;
      this.CONSTANTS.errorMessages.auctionAccess = translate.errorMessages.auctionAccess;
      this.CONSTANTS.mobilePhoneClause = translate.mobilePhoneClause;
    });

    this.translocoService.selectTranslateObject('common').pipe(takeUntil(this.destroyed$)).subscribe(translate => {
      this.CONSTANTS.button.add = translate.btnAdd;
      this.CONSTANTS.button.cancel = translate.btnCancel;
      this.CONSTANTS.button.continue = translate.btnContinue;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  recreateAuctionAccessId() {
    !this.isAuctionAccessIdOptional ? this.auctionAccess = new UntypedFormControl('', Validators.compose([Validators.required, Validators.compose([Validators.max(this.auctionAccessIndividualIdMaxValue), Validators.min(this.auctionAccessIndividualIdMinValue), Validators.pattern(this.numberPattern)])])) :
      this.auctionAccess = new UntypedFormControl('', Validators.compose([Validators.max(this.auctionAccessIndividualIdMaxValue), Validators.min(this.auctionAccessIndividualIdMinValue), Validators.pattern(this.numberPattern)]));
    this.userForm = new UntypedFormGroup({
      firstName: this.firstName,
      lastName: this.lastName,
      mobilePhone: this.mobilePhone,
      userEmail: this.email,
      auctionAccess: this.auctionAccess,
    });
    this.userForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(values => assign(this.registrationState, values));
  }
}
