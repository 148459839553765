import { CurrencyPipe } from '@angular/common';
import { AccountType } from 'remarketing-angular-library';
import { UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { get } from 'lodash';

const usCurrencyObj = new CurrencyPipe('en-US');

export function getLocalDelivery(accountTypes: AccountType[]): boolean {
  return accountTypes.some(item => item.name.toLowerCase() === 'local');
}

export function getLocalDeliveryString(hasLocalDelivery: boolean) {
  let returnValue = 'N/A';
  if (hasLocalDelivery === true) {
    returnValue = 'Yes';
  } else if (hasLocalDelivery === false) {
    returnValue = 'No';
  }
  return returnValue;
}

/**
 *
 * @param amount - incoming currency value which will accept number or string type
 * @returns - returns string value
 */
export function formatCurrency(amount): string {
  const currencyVal = parseFloat(amount);
  return usCurrencyObj.transform(currencyVal, 'USD', 'symbol', '1.0-0');
}

export function getVehicleTitle(
  modelYear?: number,
  make?: string,
  model?: string,
  trim?: string,
  bodyConfiguration?: string,
  driveTrainAbbrev?: string
): string {
  modelYear = modelYear || null;
  make = make || '';
  model = model || '';
  trim = trim || '';
  bodyConfiguration = bodyConfiguration || '';
  driveTrainAbbrev = driveTrainAbbrev || '';
  return `${modelYear} ${make} ${model} ${trim} ${bodyConfiguration} ${driveTrainAbbrev}`;
}

export function checkMobile() {
  return window.innerWidth < 768;
}

export function showHomePageBackLink(state: any): boolean {
  return state.from === '/dashboard' ? true : false;
}

/*
  function to split an array into an array of arrays, where the length of each array is determined by the chunkSize

  if array has an index of 5:
  chunkArrayBySize(array, 3)
  returns = [
      0: [ 0: vehicleUuid ], [ 1: vehicleUuid ], [ 2: vehicleUuid ]
      1: [ 0: vehicleUuid ], [ 1: vehicleUuid ]
    ]
*/
export function chunkArrayBySize(arr, chunkSize) {
  const chunkArray = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    chunkArray.push(arr.slice(i, i + chunkSize));
  }
  return chunkArray;
}

const emailPattern = '(?:[a-z0-9.!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9.!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9]{2,})?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])'
const noSpecialCharsPattern = '^[a-zA-Z0-9.+!_-]+[a-zA-Z0-9.+!_-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$';

export function emailFormatValidator(): ValidatorFn {
  let emailFormatRegex = new RegExp(emailPattern);
  return (control: AbstractControl): {[key: string]: any} => {
    if (!control.value) {
      return null;
    }
    const valid = emailFormatRegex.test(control.value);
    return valid ? null : {'invalidFormatEmail': true};
  };
}

// Emails can only contain special characters like _ . - + !
export function noSpecialCharsEmailValidator(): ValidatorFn {
  let emailFormatRegex = new RegExp(noSpecialCharsPattern);
  return (control: AbstractControl): {[key: string]: any} => {
    if (!control.value) {
      return null;
    }
    const valid = emailFormatRegex.test(control.value);
    return valid ? null : {'emailContainsSpecialChars': true};
  };
}

export function invalidFormat(formGroup: UntypedFormGroup, formName: string): boolean {
  return getErrorForm(formGroup, formName, 'invalidFormatEmail');
}
export function containsSpecialChars(formGroup: UntypedFormGroup, formName: string): boolean {
  return !invalidFormat(formGroup, formName) && getErrorForm(formGroup, formName, 'emailContainsSpecialChars');
}
export function getErrorForm(formGroup: UntypedFormGroup, formName: string, errorKeyName: string): boolean {
  return !!get(formGroup, 'controls.'.concat(formName).concat('.errors.').concat(errorKeyName));
}

